<template>
  <div class="IndustryChoice">
    <h4>热点资讯 <router-link to="/articleList">更多></router-link></h4>
    <h4>热点资讯 <router-link to="/articleList">更多></router-link></h4>
    <ul class="hotspot_list">
      <li>
        <router-link target="_blank" to="/consultDetail">
          <img class="imgs" src="../../../assets/images/a05.jpg">
          <div class="item_conten">
            <h5 class="title">工信部：加强网络安全风险隐患排查，做好行业关键信息基础设施安全防护</h5>
            <p class="item_desc">10月12日，工信部发布《关于做好2020年电信和互联网行业网络安全检查工作的通知》(以下简称《通知》)，《通知》称，将围绕加快推进网络强国建设战略目标，加强网络安全风险隐患排查，通报检查结果并加大整改力度，切实做好行业关键信息基础设施安全防护，保障电信网和公共互联网的持续安全稳定运行。</p>

            <div class="item_bottom">
              <span class="time">2020-10-13 11:42:50</span>
              <div class="item_right">
							<span class="item_rig_le">
								<img class="weixin" src="../../../assets/images/weixin.jpg">
								<a  class="erweima_box"  href="#">
									<img src="../../../assets/images/erweima.jpg">
								</a>
							</span>
                <router-link class="weibo_box" to="/">
                  <img class="weibo" src="../../../assets/images/weibo.png">
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link target="_blank" to="/consultDetail1">
          <img class="imgs" src="../../../assets/images/a08.jpg">
          <div class="item_conten">
            <h5 class="title">等保2.0和等保1.0分别有哪些标准文件</h5>
            <p class="item_desc">等级保护在2007年正式启动，而在2019年12月1日等保2.0正式实施，意味着等级保护正式进入等保2.0时代。因此，等级保护的相关标准也得到了完善，逐步采用等保2.0的标准来实施等级保护相关工作。等保2.0和等保1.0分别有哪些标准文件呢。</p>
            <div class="item_bottom">
              <span class="time">2020-09-17 10:02:55</span>
              <div class="item_right">
								<span class="item_rig_le">
									<img class="weixin" src="../../../assets/images/weixin.jpg">
									<a  class="erweima_box"  href="#">
										<img src="../../../assets/images/erweima.jpg">
									</a>
								</span>
                <router-link class="weibo_box" to="/">
                  <img class="weibo" src="../../../assets/images/weibo.png">
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link target="_blank" to="/consultDetail2">
          <img class="imgs" src="../../../assets/images/a06.jpg">
          <div class="item_conten">
            <h5 class="title">等级保护和网络安全法的关系？</h5>
            <p class="item_desc">等级保护工作是国家网络安全的基础性工作，是网络安全法要求我们履行的一项安全责任。网络安全法是网络安全领域的基本法，从国家层面对等级保护工作的法律认可，网络安全法中明确的提到信息安全的建设要遵照等级保护标准来建设。</p>
            <div class="item_bottom">
              <span class="time">2019-08-21 14:50:31</span>
              <div class="item_right">
								<span class="item_rig_le">
									<img class="weixin" src="../../../assets/images/weixin.jpg">
									<a  class="erweima_box"  href="#">
										<img src="../../../assets/images/erweima.jpg">
									</a>
								</span>
                <router-link class="weibo_box" to="/">
                  <img class="weibo" src="../../../assets/images/weibo.png">
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link target="_blank" to="/consultDetail3">
          <img class="imgs" src="../../../assets/images/a07.jpg">
          <div class="item_conten">
            <h5 class="title">周晓峰：什么是信息安全风险评估</h5>
            <p class="item_desc">依据国家信息安全标准《信息安全技术 信息安全风险评估规范》(GB/T 20984-2007)的定义，信息安全风险评估是对信息系统及由其处理、传输和存储的信息的机密性、完整性和可用性等安全属性进行评价的过程。</p>
            <div class="item_bottom">
              <span class="time">2019-10-18 14:16:51</span>
              <div class="item_right">
								<span class="item_rig_le">
									<img class="weixin" src="../../../assets/images/weixin.jpg">
									<a  class="erweima_box"  >
										<img src="../../../assets/images/erweima.jpg">
									</a>
								</span>
                <router-link class="weibo_box" to="/">
                  <img class="weibo" src="../../../assets/images/weibo.png">
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "IndustryChoice"
}
</script>

<style type="text/css" scoped>
.IndustryChoice{
  width:1200px;
  margin:0 auto;
  margin-top:20px;
  position: relative;
  z-index: 1;
}
.IndustryChoice h4{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 24px;
  color: #181818;
  line-height: 35px;
  height: 35px;
  padding-left:3px;
  padding-top:5px;
  overflow:hidden;
}
.IndustryChoice h4 a{
  font-size:14px;
  position: absolute;
  color:#888;
  right:10px;
  line-height: 20px;
  padding:3px 8px;
  border-radius: 12px;
  border:1px solid #888;
  display: inline-block;
  top:15px;
}
.IndustryChoice h4 a:hover{
  color:#0e82cb;
  border:1px solid #0e82cb;
}
/*.hotspot::before{
 			content:"";
    	    background: url("../../../assets/images/51y.png");
    		opacity:0.1;
 			z-index:-1;
 			background-size:600px 400px;
 			width:1200px;
 			height:400px;
 			position:absolute;
 			top:0px;
 			left:0px;
 			border-radius:40px;
 		}*/
.IndustryChoice_list{
  position: relative;
  width:100%;
  display: flex;
  background:#fff;
  flex-wrap: wrap;
  z-index: 1;
}
.IndustryChoice_list li{
  width:50%;
  box-sizing: border-box;
  overflow:hidden;
  margin-bottom:30px;
}
.IndustryChoice_list>li>a{
  overflow:hidden;
  width:100%;
  padding-left:15px;
  height:150px;
  display: block;
}
.IndustryChoice_list li .imgs{
  width:205px;
  height:130px;
  padding-top:15px;
  float: left;
  margin-right:10px;
}
.item_conten{
  float:left;
  width:365px;
  height:150px;
  padding:10px;
  position: relative;
  box-sizing: border-box;
}
.IndustryChoice_list li:hover .title{
  color:#0e82cb;
}
.item_conten .title{
  font-size:16px;
  font-weight: bold;
  color:#333;
  margin-bottom:10px;
  line-height:25px;
}
.item_conten .item_desc{
  font-size:14px;
  color:#666;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:2; /*2表示只显示2行*/
  -webkit-box-orient: vertical;
}
.item_bottom{
  position: absolute;
  bottom:0px;
  width:348px;
  box-sizing: border-box;
}
.time{
  font-size:12px;
  float: left;
  color:#666;
  bottom:5px;
}
.item_right{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom:0px;
  right:0;
}
.item_rig_le{
  float: right;
  margin-right:10px;
}
.item_rig_le .weixin{
  /*float:right;*/
  width:20px;
  height:20px;
}
.weibo_box{
  margin-right:35px;
  float:right;
}
.weibo{
  width:22px;
  height:22px;
}
.item_rig_le:hover  .erweima_box{
  display: block;
}
.erweima_box{
  position: absolute;
  top:-80px;
  left:-30px;
  display: none;
}
.erweima_box img{
  width:80px;
  height:80px;
}


</style>
