<!--<template>-->

<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "index20210701"-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->



<template>
  <div>
    <el-container>
      <!-- <Headers/> -->
      <MainNew/>
      <applicationCenter/>
      <Hotspot/>
      <IndustryChoice/>
      <el-footer>
        <!-- <Footer/> -->
      </el-footer>
    </el-container>
    <!-- <el-backtop></el-backtop> -->
  </div>
</template>

<script>
// import Headers from "@/components/Header.vue"
import MainNew from "@/pages/comm/Main.vue"
import applicationCenter from "./component/applicationCenter.vue"
import Hotspot from "./component/hotspot.vue"
import IndustryChoice from "./component/IndustryChoice.vue"
// import Case from "./component/case.vue"
// import Footer from "@/components/Footer.vue"

export default {
  name: "index20210701",
  components:{
    // Headers,
    MainNew,
    applicationCenter,
    Hotspot,
    IndustryChoice,
    // Case,
    // Footer
  },
  data() {
    return {
      circleUrl:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      currentDate: new Date(),
      searchInfo: "",

    };
  },
  mounted() {},
  watch: {},
  methods: {

  }
};
</script>

<style scoped >

.el-container {
  /*margin: 0 auto;*/
}
.el-footer{
  padding:0;
  height:auto !important;
  /*margin-top:25px;*/
}
.el-backtop{
  z-index:15;

}


</style>





